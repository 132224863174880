import React from "react"
import Layout from "../Components/layout";
import Carousel from "../Components/home/carousel"
import Why from "../Components/home/why"
import CardSection from "../Components/home/cards"
import Carousel2 from "../Components/home/carousel2"
import InquireSection from "../Components/inquirelink"
import SEO from "../Components/SEO"

export default function Home() {
  return (
    <Layout>
      <SEO title="Home" description="Fastlink Home Page"></SEO>
      <Carousel></Carousel>
      <Why></Why>
      <CardSection></CardSection>
      <Carousel2></Carousel2>
      <InquireSection></InquireSection>
    </Layout>
  )
}
