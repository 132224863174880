import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import {
    Container, Row, Col, Button
} from "reactstrap"

import style from "./styles.module.css"

const CardSection = props => {


    const data = useStaticQuery(graphql`
    {
    allFile(filter: {relativeDirectory: {eq: "home/cards"}}) {
        nodes {
            childImageSharp {
            fixed(width: 125, height: 125) {
                ...GatsbyImageSharpFixed
            }
            }
        }
        }
    }
  `)


    return (
        <Container fluid sm="12" className={style.yellowContainer}>
            <Row className="text-center">
                <Col md="12" className="text-center">
                    <h1 className="mx-auto mb-4 heading1 p-3"><span>What do we offer</span></h1>
                </Col>
            </Row>
            <div className={style.cardContainer}>
                <div className={style.card}>
                    <div>
                        <Img fixed={data.allFile.nodes[0].childImageSharp.fixed}></Img>
                    </div>
                    <h3>Products</h3>
                    <p>
                        Fastlink offers a wide variety of products that will cater all the consumer and enterprise's needs, wants, and requirements.
                        </p>
                    <Link to="/products"> <Button outline className={style.button1}>Learn More</Button> </Link>
                </div>

                <div className={style.card}>
                    <div >
                        <Img fixed={data.allFile.nodes[2].childImageSharp.fixed}></Img>
                    </div>
                    <h3>Solutions</h3>
                    <p>
                        Fastlink is equipped with knowledgeable and experienced manpower that are certified experts.
                        </p>
                    <Link to="/solutions"> <Button outline className={style.button1}>Learn More</Button> </Link>
                </div>

                <div className={style.card}>
                    <div>
                        <Img fixed={data.allFile.nodes[1].childImageSharp.fixed}></Img>
                    </div>
                    <h3>Services</h3>
                    <p>
                        For over 19 years, Fastlink has been producing and delivering efficient solutions that addresses our clients' ICT Requirements.
                        </p>
                    <Link to="/services"> <Button outline className={style.button1}>Learn More</Button> </Link>
                </div>




            </div>

        </Container>
    )
}

export default CardSection
