import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
} from 'reactstrap';

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"



const HomeCarousel = (props) => {
    const data = useStaticQuery(graphql`
    {
    allFile(filter: {relativeDirectory: {eq: "home/carousel"}}) {
        nodes {
            childImageSharp {
            fluid(maxHeight: 700) {
                ...GatsbyImageSharpFluid
            }
            id
            }
        }
        }
    }
  `)

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const items = data.allFile.nodes

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const slides = items.map((slide) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={slide.id}
                style={{
                    maxHeight: '82vh'
                }}
            >
                <div style={{
                    maxHeight: '82vh'
                }}>
                    <Img fluid={slide.childImageSharp.fluid} style={{
                        width: "100vw",
                        backgroundPosition: "center center",
                    }}></Img>
                </div>
            </CarouselItem>
        );
    });

    return (
        <div style={{
            overflowY: 'hidden',
            // background: 'red'
        }}>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                style={{
                    height: '80vh',
                    marginBottom: '10px'
                }}
            >
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
        </div>
    );
}

export default HomeCarousel;