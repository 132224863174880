import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import style from "./styles.module.css"
import Carousel from "../pageComponents/carousel"

const Carousel2 = props => {
    const data = useStaticQuery(graphql`
    {
        allFile(filter: {relativeDirectory: {eq: "home/carousel2"}}) {
            nodes {
                childImageSharp {
                    fixed(width: 280, height: 280) {
                        ...GatsbyImageSharpFixed
                    }
                    id
                }
            }
        }
    }
  `)

    const items = data.allFile.nodes

    const slides = items.map((slide, index) => {
        return (
            <div key={index} className={style.ccard}>
                <Img fixed={slide.childImageSharp.fixed}></Img>
            </div>
        );
    });

    return (
        <div className={style.carouselContainer}>
            <h1 className="heading1 text-center"><span>Featured Products</span></h1>
            <Carousel >
                {slides}
            </Carousel>
        </div>
    )
}

export default Carousel2
