import React from "react"

import { Container, Row, Col, Button } from "reactstrap"
import style from "./styles.module.css"
import { Link } from "gatsby"


const Why = props => {
    return (
        <Container className="py-5">
            <Row>
                <Col md="12" className="text-center">
                    <h1 className="mx-auto mb-4 heading1 mt-3"><span>Why With Us?</span></h1>

                </Col>
            </Row>
            <Row className="text-center mx-auto">
                <p>
                    Fastlink prides itself with the competence in different global, best of breed ICT products and solutions. With a workforce that is exposed to multi-industry workflow experiences, coupled with the technical competence through Certification Trainings and Product Familiarity, Fastlink can guarantee only the best results and business solutions that are a LINK away.
                    </p>
            </Row>
            <div className="text-center mb-4">
                <Link to="/about"> <Button outline className={style.button1}>Learn More</Button> </Link>
            </div>
        </Container >
    )
}

export default Why
